<template>
  <div style="background-color: #e91e55">
    <div style="background-color: #ff9c4f; width: 100%; height: 1rem"></div>
    <v-container style="margin-top: 4rem; margin-bottom: 5rem">
      <v-layout row wrap>
        <div
          class="footer-element"
          style="border-right: 1px solid #ff9c4f; min-width: 200px"
        >
          <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="150"
            :src="LogoGallagherLight"
            max-width="175"
          />
        </div>
        <div
          class="footer-element"
          style="border-right: 1px solid #41b0e7; min-width: 200px"
        >
          <div style="height: 100%">
            <ul style="list-style: none; padding-left: 0">
              <a href="https://www.aseguraonline.cl/"><li>Seguros</li></a> 
              <router-link style="text-decoration: none" :to="{ path: '/cotizador' }" > <li>Cotizador</li> </router-link>
              <a href="https://www.aseguraonline.cl/quienes-somos/"><li>Quienes Somos</li></a>
              <a href="https://www.aseguraonline.cl/contacto/"><li>Contacto</li></a> 
              <a href="https://www.aseguraonline.cl/denuncia-tu-siniestro/"><li>Siniestros</li></a>
            </ul>
          </div>
        </div>
        <div
          class="footer-element"
          style="border-right: 1px solid #41b0e7; min-width: 300px"
        >
          <div style="height: 100%">
            <div style="margin-bottom: 1.5rem">
              <p>Dirección</p>
              <p>Av. Andres Bello 1245, of. 606 Providencia - Chile</p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>
                Teléfono:
                <a style="color: #41b0e7" href="tel:+56 2 26384658">+56 2 26384658</a>
              </p>
            </div>
            <div style="margin-bottom: 1.5rem">
              <p>
                WhatsApp:
                <a style="color: #41b0e7" href="https://api.whatsapp.com/send?phone=56932265306">+56 932265306</a>
              </p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>
                Email:
                <a style="color: #41b0e7" mailto="info@seguroslascondes.cl">info@seguroslascondes.cl</a>
              </p>
            </div>
          </div>
        </div>
        <div class="footer-element" style="min-width: 150px">
          <v-col class="ma-0 pa-0">
            <div style="height: 100%">Síguenos en</div>
            <v-row class="ma-0 pa-0">
              <v-btn
                class="ma-0"
                text
                icon
                color="secondary"
                href="https://www.instagram.com/aseguraonline.cl/"
                target="_blank"
              >
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn
                class="ma-0"
                text
                icon
                color="secondary"
                href="https://www.facebook.com/AseguraOnlinecl-111047938173957"
                target="_blank"
              >
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn
                class="ma-0"
                text
                icon
                color="secondary"
                href="https://www.linkedin.com/company/asegura-online/"
                target="_blank"
              >
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
            </v-row>
             <br>
              Este riesgo es tomado por         
              <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="100"
            :src="LogoSB"
            max-width="175"
          />
          </v-col>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import LogoGallagherLight from "@/assets/images/aseguraonline_footer.png";
import LogoSB from "@/assets/images/SB_logo.png";

export default {
  name: "Footer",
  data: () => ({
    LogoGallagherLight,
    LogoSB
  }),
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.footer-element {
  color: white;
  min-height: 200px;
  margin: 1rem;
  display: flex;
  align-items: flex-start;
  a{
    color: unset;
    text-decoration: none;
    li {
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }
  li {
    margin-bottom: 1rem;
    cursor: pointer;
  }


  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }
}

.layout.wrap {
  justify-content: space-around;
}
</style>
