<template>
  <v-container>
    <div class="benefits">
      <div class="benefit-detail">
        <v-img contain max-height="130" :src="Benefit1" max-width="130" />
        <h3>Flexible</h3>
        <h4>Tú eliges la veterinaria que te da mayor confianza.</h4>
      </div>
      <div class="benefit-detail">
        <v-img contain max-height="130" :src="Benefit2" max-width="130" />
        <h3>Sin ataduras</h3>
        <h4>Puedes dar de baja tu seguro cuando quieras.</h4>
      </div>
      <div class="benefit-detail">
        <v-img contain max-height="130" :src="Benefit3" max-width="130" />
        <h3>Personalizado</h3>
        <h4>Puedes elegir el plan que más se acomode a tus necesidades.</h4>
      </div>
      <div class="benefit-detail">
        <v-img contain max-height="130" :src="Benefit4" max-width="130" />
        <h3>Preventivos</h3>
        <h4>Cubrimos las vacunas tradicionales de tus peludos: antirrábica y séxtuple.</h4>
      </div>
    </div>
  </v-container>
</template>

<script>
import Benefit1 from "@/assets/images/Benefit1.svg";
import Benefit2 from "@/assets/images/Benefit2.svg";
import Benefit3 from "@/assets/images/Benefit3.svg";
import Benefit4 from "@/assets/images/Benefit4.svg";

export default {
  name: "Benefits",
  data: () => ({
    Benefit1,
    Benefit2,
    Benefit3,
    Benefit4,
  }),
};
</script>

<style lang="scss">
.benefits {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4rem 0;

  .benefit-detail {
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      text-align: center;
    }

    h4 {
      text-align: center;
      font-weight: normal;
    }
  }
}

@media (max-width: 768px){
  .benefits{
    flex-direction: column;
    align-items: center;
  }
}
</style>